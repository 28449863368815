.App {
  display: flex;
  flex-direction: column;
  margin: 1em;
}

.post {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.post_title {
  font-weight: bold;
  font-size: 1.5em;
}

.post_content {
  display: flex;
  flex-direction: row;
  padding: 1em;
  flex: 1;
}

.post_author > span {
  font-weight: 100;
  color: #3f3f3f
}

form {
  display: flex;
  flex-direction: column;
  width: 16em;
}

form > textarea  {
  width: 50em;
  height: 10em;
  margin-bottom: 0.5em;
}